
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"
import { DiAndroid } from "react-icons/di";
import { DiJsBadge } from "react-icons/di";
import { DiWindows } from "react-icons/di";
import { DiAtom } from "react-icons/di";

import kotlin from "../assets/img/kotlin.svg";
import javascript from "../assets/img/javascript.svg";
import asp from "../assets/img/asp.svg";
import reacticon from "../assets/img/reacticon.svg";



export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Plataformas de desarrollo</h2>
                        
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={kotlin} alt="Image" />
                                <h5>Desarrollo de apps <DiAndroid/></h5>
                            </div>
                            <div className="item">
                                <img src={javascript} alt="Image" />
                                <h5>Desarrollo web <DiJsBadge/></h5>
                            </div>
                            <div className="item">
                                <img src={asp} alt="Image" />
                                <h5>Desarrollo de escritorio <DiWindows/></h5>
                            </div>
                            <div className="item">
                                <img src={reacticon} alt="Image" />
                                <h5>Diseño de interfaz <DiAtom/></h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
